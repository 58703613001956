<template>
  <div class="asset-cleanup-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="DetailInfo" :tab="$t(`清理资产详情`)"></a-tab-pane>
          <a-tab-pane key="FeeInfo" :tab="$t(`清理收入/费用详情`)"></a-tab-pane>
          <a-tab-pane key="Attachment" :tab="$t(`附件`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.orderStatus === 'WAIT_CHECK'"
            api="operateAssetCleanup"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.orderStatus)"
            api="operateAssetCleanup"
            type="danger"
            :text="$t('作废')"
            :params="{
              action: 'DELETE',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="isDetail && !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.orderStatus)"
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox id="BaseInfo" :title="$t('基本信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据编号`)">
                    <a-input disabled v-model="formData.orderCode"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`货主组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :placeholder="$t('货主组织')"
                      :disabled="isDisabled"
                      :code.sync="formData.ownerBusinessUnitCode"
                      :name.sync="formData.ownerBusinessUnitName"
                      @change="getCurrentAccountingPeriod"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabledDate="disabledDate"
                      @choose="chooseAccountingDate"
                      :disabled="isDisabled"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`减少方式`)" required>
                    <CommonQuerySelect
                      :placeholder="$t('减少方式')"
                      :params="{
                        inDecreaseType: 'DECREASE',
                      }"
                      api="getInDecreaseMethodList"
                      :disabled="isDisabled"
                      :code.sync="formData.subtractType"
                      :name.sync="formData.subtractTypeName"
                      valueName="in_decrease_code"
                      labelName="in_decrease_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`清理原因`)">
                    <a-input
                      :placeholder="$t(`清理原因`)"
                      :disabled="isDisabled"
                      v-model="formData.cleanupReason"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>

            <ModalBox id="DetailInfo" :title="$t('清理资产详情')">
              <template v-slot:header>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="showAssetCardModal">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml10" @click="deleteAssetCard">
                    {{ $t('删行') }}
                  </span>
                </div>
              </template>
              <CommonTable
                :columns.sync="columns"
                :dataSource="formData.detailList"
                :scroll="{ x: 1500 }"
                :selectedRowKeys.sync="selectedRowKeys"
                :showSelect="!isDisabled"
                :showPagination="false"
              >
                <template v-slot:cleanupNum="{ text, record }">
                  <span v-if="record.type === 'summary'">{{ text }}</span>
                  <a-input-number
                    :value="record.cleanupNum"
                    :disabled="isDisabled"
                    v-else
                    @blur="
                      (val) => {
                        numBlur(val, record)
                      }
                    "
                  />
                </template>
                <template v-slot:cleanupAssetOriginalCost="{ text }">
                  <PriceInput :value="text" :showTxt="true" />
                </template>
                <template v-slot:bizDate="{ record }">
                  <DatePicker
                    :needhms="false"
                    style="background-color: #ffffff; width: 100%"
                    :init-date-time="record.bizDate"
                    @choose="
                      (val) => {
                        chooseBizDate(val, record)
                      }
                    "
                    :disabled="isDisabled"
                    :placehold="$t(`业务日期`)"
                  />
                </template>
              </CommonTable>
            </ModalBox>

            <ModalBox id="FeeInfo" :title="$t('清理收入/费用详情')">
              <CommonTable
                :columns.sync="columns2"
                :dataSource="formData.detailList"
                :scroll="{ x: 1500 }"
                :showSelect="false"
                :showPagination="false"
              >
                <template v-slot:cleanupFee="{ record }">
                  <PriceInput
                    :value.sync="record.cleanupFee"
                    :disabled="isDisabled"
                    :showTxt="record.type === 'summary'"
                    @blur="calTotalLine"
                  />
                </template>
                <template v-slot:cleanupIncome="{ record }">
                  <PriceInput
                    :value.sync="record.cleanupIncome"
                    :disabled="isDisabled"
                    :showTxt="record.type === 'summary'"
                    @blur="onBlur(record)"
                  />
                </template>
                <template v-slot:taxRate="{ record }">
                  <a-input-number
                    v-model="record.taxRate"
                    :disabled="isDisabled"
                    @blur="onBlur(record)"
                  />
                </template>
                <template v-slot:taxAmount="{ record }">
                  <PriceInput :value.sync="record.taxAmount" :disabled="isDisabled" />
                </template>
              </CommonTable>
            </ModalBox>

            <ModalBox id="Attachment" :title="$t('附件')">
              <div class="attachment-list">
                <Attachment ref="AttachmentRef" :list="fileList" :disabled="isDisabled" />
              </div>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.orderStatus"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <SelectAssetCardModal
      ref="AssetCardModal"
      assetOperateCode="CLEANUP"
      :hasSelected="hasSelected"
      :ownerBusinessUnitCode="formData.ownerBusinessUnitCode"
      @ok="handleAssetCardOk"
    ></SelectAssetCardModal>
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import SelectAssetCardModal from '@component/selectAssetCardModal'
import Attachment from '@component/attachment'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
export default {
  name: 'AssetCleanupDetail',
  components: { DatePicker, BottomBtns, OperationLog, Attachment, SelectAssetCardModal },
  data() {
    return {
      docType: 'AssetCleanup',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        orderStatus: 'DRAFT',
        detailList: [],
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      fileList: [],
      endDate: undefined,
      startDate: undefined,

      selectedRowKeys: [],
      columns: [],
      columns2: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
    hasSelected() {
      return this.formData.detailList?.map((item) => item.id)
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
      this.columns = [
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
        },
        {
          title: this.$t('卡片编号'),
          dataIndex: 'assetCardNo',
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('会计政策'),
          dataIndex: 'accountingPolicyName',
        },
        {
          title: this.$t('资产数量'),
          dataIndex: 'assetNum',
        },
        {
          title: this.$t('清理数量'),
          dataIndex: 'cleanupNum',
          scopedSlots: {
            customRender: 'cleanupNum',
          },
        },
        {
          title: this.$t('计量单位'),
          dataIndex: 'unitName',
        },
        {
          title: this.$t('清理资产原值'),
          dataIndex: 'cleanupAssetOriginalCost',
          scopedSlots: {
            customRender: 'cleanupAssetOriginalCost',
          },
        },
        {
          title: this.$t('清理资产折旧'),
          dataIndex: 'cleanupAccumulatedDepreciation',
          customRender: (text) => this.showPrice(text),
        },
        {
          title: this.$t('清理减值准备'),
          dataIndex: 'cleanupImpairmentProvision',
          customRender: (text) => this.showPrice(text),
        },
        {
          title: this.$t('清理资产净额'),
          dataIndex: 'cleanupNetAmount',
          customRender: (text) => this.showPrice(text),
        },
        {
          title: this.$t('清理资产残值'),
          dataIndex: 'cleanupNetResidualValue',
          customRender: (text) => this.showPrice(text),
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          scopedSlots: {
            customRender: 'bizDate',
          },
        },
      ]
      this.columns2 = [
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
        },
        {
          title: this.$t('卡片编号'),
          dataIndex: 'assetCardNo',
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('清理费用'),
          dataIndex: 'cleanupFee',
          scopedSlots: {
            customRender: 'cleanupFee',
          },
        },
        {
          title: this.$t('清理收入(含税)'),
          dataIndex: 'cleanupIncome',
          scopedSlots: {
            customRender: 'cleanupIncome',
          },
        },
        {
          title: this.$t('税率') + '(%)',
          dataIndex: 'taxRate',
          scopedSlots: {
            customRender: 'taxRate',
          },
        },
        {
          title: this.$t('税额'),
          dataIndex: 'taxAmount',
          scopedSlots: {
            customRender: 'taxAmount',
          },
        },
      ]
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    showPrice(num) {
      if (num) {
        return this.$currencySymbol(num, 'CNY')
      }
    },
    disabledDate(current) {
      return current && (current > moment(this.endDate) || current < moment(this.startDate))
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    chooseBizDate(data, record) {
      record.bizDate = data.data
    },
    async getCurrentAccountingPeriod() {
      let res = await http({
        url: api.getCurrentAccountingPeriod,
        data: {
          ownerBusinessUnitCode: this.formData.ownerBusinessUnitCode,
        },
      })
      this.startDate = res.result.start_date
      this.endDate = res.result.end_date
      this.formData.accountingDate = res.result.end_date
    },
    async getDetailInfo() {
      if (this.id) {
        this.loading = true
        await http({
          url: api.getAssetCleanupDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.formData = deepClone(result)
            if (result.orderStatus === 'DRAFT') {
              this.isEdit = true
            }
          },
        })
        this.loading = false
      }
    },
    backForm(back = 0) {
      goBack('assetChangeList', back)
    },
    onSubmit: debounce(async function (status) {
      let attachmentList = await this.$refs.AttachmentRef.changeUpload()
      const data = deepClone(this.formData)
      data.attachmentList = attachmentList
      data.orderStatus = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.merchantCode = localStorage.getItem('merchantCode')
      await http({
        url: api.createAssetCleanup,
        data: {
          assetCleanup: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    showAssetCardModal() {
      this.$refs.AssetCardModal.open()
    },
    deleteAssetCard() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
      this.calTotalLine()
    },
    handleAssetCardOk(list) {
      let detailList = list.map((item) => ({
        accountingPolicyCode: item.accountingPolicyCode,
        accountingPolicyName: item.accountingPolicyName,
        assetBarcodes: item.barcode,
        assetCardId: item.id,
        assetCardNo: item.cardNo,
        assetCode: item.assetCode,
        assetName: item.assetName,
        assetNum: item.quantity,
        bizDate: moment().format('YYYY-MM-DD'),
        cleanupAccumulatedDepreciation: item.accumulatedDepreciation,
        cleanupAssetOriginalCost: item.originalValue,
        cleanupImpairmentProvision: item.impairmentProvision,
        cleanupNetAmount: item.netAmount,
        cleanupNetResidualValue: item.netValue,
        cleanupNum: item.quantity,
        unit: item.unit,
        unitName: item.unitName,
        cleanupFee: undefined, //清理费用
        cleanupIncome: undefined, //清理收入
        taxRate: undefined,
        taxAmount: undefined,
      }))
      this.formData.detailList = [...this.formData.detailList, ...detailList]
      this.calTotalLine()
      this.$refs.AssetCardModal.close()
    },
    calTotalLine() {
      this.formData.detailList = this.formData.detailList.filter((item) => item.type !== 'summary')
      if (this.formData.detailList.length === 1) return
      let totalAssetNum = 0,
        totalCleanupAccumulatedDepreciation = 0,
        totalCleanupAssetOriginalCost = 0,
        totalCleanupImpairmentProvision = 0,
        totalCleanupNetAmount = 0,
        totalCleanupNetResidualValue = 0,
        totalCleanupNum = 0,
        totalCleanupFee = 0,
        totalCleanupIncome = 0

      this.formData.detailList.forEach((item) => {
        totalAssetNum += item.assetNum || 0
        totalCleanupAccumulatedDepreciation += item.cleanupAccumulatedDepreciation || 0
        totalCleanupAssetOriginalCost += item.cleanupAssetOriginalCost || 0
        totalCleanupImpairmentProvision += item.cleanupImpairmentProvision || 0
        totalCleanupNetAmount += item.cleanupNetAmount || 0
        totalCleanupNetResidualValue += item.cleanupNetResidualValue || 0
        totalCleanupNum += +item.cleanupNum || 0
        totalCleanupFee += +item.cleanupFee || 0
        totalCleanupIncome += +item.cleanupIncome || 0
      })

      const summaryLine = {
        type: 'summary',
        assetNum: Number(totalAssetNum),
        cleanupNum: Number(totalCleanupNum),
        cleanupAccumulatedDepreciation: Number(totalCleanupAccumulatedDepreciation.toFixed(2)),
        cleanupAssetOriginalCost: Number(totalCleanupAssetOriginalCost.toFixed(2)),
        cleanupImpairmentProvision: Number(totalCleanupImpairmentProvision.toFixed(2)),
        cleanupNetAmount: Number(totalCleanupNetAmount.toFixed(2)),
        cleanupNetResidualValue: Number(totalCleanupNetResidualValue.toFixed(2)),
        cleanupFee: Number(totalCleanupFee.toFixed(2)),
        cleanupIncome: Number(totalCleanupIncome.toFixed(2)),
      }
      this.formData.detailList.push(summaryLine)
    },
    onBlur(record) {
      this.$nextTick(() => {
        if (record.cleanupIncome && record.taxRate) {
          if (record.taxRate > 100) {
            record.taxRate = 100
          }
          record.taxAmount = Number(
            ((record.cleanupIncome * (record.taxRate / 100)) / (record.taxRate / 100 + 1)).toFixed(
              2
            )
          )
        }
      })
      this.calTotalLine()
    },
    numBlur(e, record) {
      let value = e.target.value
      if (value > record.assetNum) {
        value = record.assetNum
        this.$message.warning(this.$t('清理数量不能大于资产数量'))
      }
      record.cleanupAssetOriginalCost = Number(
        ((record.cleanupAssetOriginalCost / record.cleanupNum) * value).toFixed(2)
      )
      record.cleanupAccumulatedDepreciation = Number(
        ((record.cleanupAccumulatedDepreciation / record.cleanupNum) * value).toFixed(2)
      )
      record.cleanupImpairmentProvision = Number(
        ((record.cleanupImpairmentProvision / record.cleanupNum) * value).toFixed(2)
      )
      record.cleanupNetAmount = Number(
        ((record.cleanupNetAmount / record.cleanupNum) * value).toFixed(2)
      )
      record.cleanupNetResidualValue = Number(
        ((record.cleanupNetResidualValue / record.cleanupNum) * value).toFixed(2)
      )
      record.cleanupNum = value
      this.calTotalLine()
    },
  },
}
</script>
